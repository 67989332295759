import { gql } from "@apollo/client"

export const CITIES_QUERY = gql`
    query CitiesQuery($searchInput: String!, $first: Int!) {
        cities(searchInput: $searchInput, first: $first) {
            edges {
                node {
                    id
                    geonameId
                    longLabel
                    shortLabel
                    latitude
                    longitude
                }
            }
        }
    }
`
