import * as Yup from "yup"

export const userBioValidator = () =>
    Yup.string()
        .required("Required")
        .max(500, "Bio must be at most 500 characters")

export const urlValidator = (maxLength: number = 200) =>
    Yup.string()
        .url("Must be a valid URL (https://...)")
        .max(maxLength, `Must be under ${maxLength} characters.`)

export const yearsOfExperienceValidator = () =>
    Yup.number()
        .required("Required")
        .typeError("Must be a number")
        .integer("Must be an integer")
        .min(0, "Must be greater than or equal to 0")
        .max(99, "Must be less than 100")
