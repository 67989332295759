import { SingleSelect } from "@pallet-hq/hegel"
import { FiMail, FiMessageCircle, FiX } from "react-icons/fi"
import { WorkStatusEnum } from "__generated__/globalTypes"
import { singleSelectValidator } from "utils/validators"

const workStatusOptions = [
    {
        value: WorkStatusEnum.READY_TO_INTERVIEW,
        label: "Ready to interview",
        icon: <FiMessageCircle size={16} stroke="#F3A683" />,
        description:
            "You’re actively interviewing with different companies right now.",
    },
    {
        value: WorkStatusEnum.OPEN_TO_OFFERS,
        label: "Open to conversations",
        icon: <FiMail size={16} stroke="#2188E6" />,
        description:
            "You’re not interviewing, but you’re open to hearing about cool opportunities.",
    },
    {
        value: WorkStatusEnum.CLOSED_TO_CONVERSATIONS,
        label: "Closed to conversations",
        icon: <FiX size={16} stroke="#DD4444" />,
        description:
            "You’re not looking for a new job and don’t want to learn about other opportunities.",
    },
]

const FIELD_NAME = "workStatus" as const

const WorkStatusField = ({ label }: { label?: string }) => (
    <SingleSelect
        name={FIELD_NAME}
        label={label}
        options={workStatusOptions}
        shouldCloseMenuOnScroll
        closeMenuOnSelect
    />
)

WorkStatusField.fieldName = FIELD_NAME
WorkStatusField.validators = {
    required: { [FIELD_NAME]: singleSelectValidator({ required: true }) },
}

export { WorkStatusField, workStatusOptions }
