import {
    MAX_FILE_SIZE,
    MAX_FILE_SIZE__DEPRECATED,
    SUPPORTED_FORMATS,
} from "constants/images"
import * as Yup from "yup"
import { urlValidator } from "./sharedValidationSchemas"

export const imageValidator = () =>
    Yup.mixed()
        .test(
            "fileSize",
            "File is too large (max 4mb).",
            profileImage =>
                !profileImage || profileImage.size <= MAX_FILE_SIZE__DEPRECATED
        )
        .test(
            "fileType",
            "Unsupported file type (we support: .jpg, .jpeg or .png).",
            profileImage =>
                !profileImage || SUPPORTED_FORMATS.includes(profileImage.type)
        )

export const singleSelectValidator = (
    { required = false }: { required?: boolean } = { required: false }
) => {
    const validator = Yup.object().nullable()
    if (required)
        return validator
            .required("Required.")
            .test(
                "value-not-null",
                "Required.",
                selectedOption => !!selectedOption?.value
            )

    return validator.test(
        "if-value-ensure-non-null-value",
        "Please select a valid value.",
        selectedOption => (selectedOption ? !!selectedOption?.value : true)
    )
}

export const multiSelectValidator = () => {
    return Yup.array().of(singleSelectValidator({ required: true }))
}

export const keywordInputValidator = () => {
    return multiSelectValidator()
        .of(
            Yup.object().test(
                "keyword-length-validator",
                "Each search query must be less than 26 characters long.",
                selectedOption =>
                    typeof selectedOption.value === "string" &&
                    (selectedOption.value as String).length <= 25
            )
        )
        .max(5, "Please only search for up to 5 keywords.")
}

export const maxCharValidator = (char: number) =>
    Yup.string().max(char, `Must be under ${char} characters.`)

export const siteValidator = (site: string, intuitiveName?: string) => {
    const regex = `^https?:\\/\\/(www\\.)?${site.replace(".", "\\.")}\\/.*$`
    return urlValidator().matches(
        new RegExp(regex),
        `Must be a valid ${intuitiveName ?? site} URL`
    )
}

export const reqMaxCharValidator = (char: number) =>
    Yup.string()
        .required("Required")
        .max(char, `Must be under ${char} characters.`)

export const whiteSpaceValidator = () =>
    Yup.string().test(
        "empty-check",
        "Your message cannot be blank spaces",
        message => message?.trim().length! > 0 || message === undefined
    )

export const imageValidator2 = () =>
    Yup.mixed()
        .test(
            "fileSize",
            "Please use a smaller file (max 4MB).",
            file => !file || file.size <= MAX_FILE_SIZE
        )
        .test(
            "fileType",
            "Please use a .png, .jpeg, or .jpg file",
            file => !file || SUPPORTED_FORMATS.includes(file.type)
        )
