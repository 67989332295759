/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum ApplicationStatusEnum {
  APPROVED = "APPROVED",
  INCOMPLETE = "INCOMPLETE",
  NOT_STARTED = "NOT_STARTED",
  REJECTED = "REJECTED",
  SUBMITTED = "SUBMITTED",
}

/**
 * Used for ApplyToCollective mutation
 */
export enum CandidateApplicationStepEnum {
  START = "START",
  SUBMIT = "SUBMIT",
}

/**
 * List of candidate feeds that a recruiter can access.
 */
export enum CandidateFeedEnum {
  INBOUND = "INBOUND",
  PALLET = "PALLET",
  PROJECT = "PROJECT",
}

/**
 * The states a Checkout can be in.
 */
export enum CheckoutStatusEnum {
  CANCELED = "CANCELED",
  COMPLETE = "COMPLETE",
  EXPIRED = "EXPIRED",
  REQUIRES_PAYMENT = "REQUIRES_PAYMENT",
}

/**
 * How invoices collect payment.
 */
export enum CollectionMethodEnum {
  charge_automatically = "charge_automatically",
  send_invoice = "send_invoice",
}

/**
 * Possible additional statuses for CompanySubscriptionType
 */
export enum CompanySubscriptionAdditionalStatusEnum {
  PAYMENT_PENDING = "PAYMENT_PENDING",
}

/**
 * Used for CompanySubscriptionType arguments
 */
export enum CompanySubscriptionStatusEnum {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * Type of subscription for CompanySubscriptionType
 */
export enum CompanySubscriptionTypeEnum {
  CANDIDATESEARCH = "CANDIDATESEARCH",
  COLLECTIVE = "COLLECTIVE",
}

/**
 * The progress a conversation can have in intros.
 */
export enum ConversationStatusEnum {
  HIRED = "HIRED",
  NOT_CONNECTED = "NOT_CONNECTED",
  OFFERED = "OFFERED",
  ONSITE = "ONSITE",
  PASSED = "PASSED",
  STILL_INTERVIEWING = "STILL_INTERVIEWING",
}

/**
 * The duration of a Coupon, based on Stripe's Enum
 */
export enum CouponDurationEnum {
  FOREVER = "FOREVER",
  ONCE = "ONCE",
  REPEATING = "REPEATING",
}

/**
 * An enumeration.
 */
export enum CreatedBySpecifierEnum {
  ALL = "ALL",
  ME = "ME",
  OTHERS = "OTHERS",
}

/**
 * An enumeration.
 */
export enum DropStatusEnum {
  DEFERRED = "DEFERRED",
  DROPPED = "DROPPED",
  INELIGIBLE = "INELIGIBLE",
  UPCOMING = "UPCOMING",
}

/**
 * The reasons a Checkout can be exempted.
 */
export enum ExemptionReasonEnum {
  CREDIT_APPLIED = "CREDIT_APPLIED",
  EXEMPTED_BY_PROXY = "EXEMPTED_BY_PROXY",
  EXEMPTED_BY_STAFF = "EXEMPTED_BY_STAFF",
  IS_ADMIN = "IS_ADMIN",
}

/**
 * The status of an export.
 */
export enum ExportStatus {
  ERROR = "ERROR",
  PENDING = "PENDING",
  READY = "READY",
}

/**
 * An enumeration.
 */
export enum InteractionTypeEnum {
  SAVED = "SAVED",
  SKIPPED = "SKIPPED",
}

/**
 * The ways to order intros.
 */
export enum IntroRequestOrderByEnum {
  CREATED_AT = "CREATED_AT",
  UPDATED_AT = "UPDATED_AT",
}

/**
 * An enumeration.
 */
export enum IntroSourceEnum {
  COLLECTIVE = "COLLECTIVE",
  SPOTLIGHT = "SPOTLIGHT",
}

/**
 * An enumeration.
 */
export enum IntroStatusEnum {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  SENT = "SENT",
}

export enum ListingDisplayStatusEnum {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DRAFT = "DRAFT",
  EXPIRED = "EXPIRED",
  HIDDEN = "HIDDEN",
  IN_REVIEW = "IN_REVIEW",
  PARTNERSHIP_CANCELLED = "PARTNERSHIP_CANCELLED",
  PARTNERSHIP_PENDING = "PARTNERSHIP_PENDING",
  PARTNERSHIP_REJECTED = "PARTNERSHIP_REJECTED",
  REJECTED = "REJECTED",
}

/**
 * The type of location entity (e.g. city, country)
 */
export enum LocationTypeEnum {
  CITY = "CITY",
  CONTINENT = "CONTINENT",
  COUNTRY = "COUNTRY",
  REGION = "REGION",
  SUBREGION = "SUBREGION",
}

/**
 * The office preference options (e.g. onsite/remote)
 */
export enum OfficePreferenceEnum {
  ONSITE = "ONSITE",
  ONSITE_OR_REMOTE = "ONSITE_OR_REMOTE",
  REMOTE = "REMOTE",
}

/**
 * Timeframes used for filtering cached pallet analytics.
 */
export enum PalletAnalyticsTimeframeOptions {
  ALL_TIME = "ALL_TIME",
  SIX_MONTHS = "SIX_MONTHS",
  THIRTY_DAYS = "THIRTY_DAYS",
  THREE_MONTHS = "THREE_MONTHS",
  TWO_WEEKS = "TWO_WEEKS",
  YEAR = "YEAR",
}

/**
 * An enumeration.
 */
export enum PalletDataExportCategoryEnum {
  CANDIDATES = "CANDIDATES",
  CUSTOMERS = "CUSTOMERS",
  FOLLOWERS = "FOLLOWERS",
  INTROS = "INTROS",
  JOB_POSTS = "JOB_POSTS",
}

/**
 * An enumeration.
 */
export enum PostOriginEnum {
  FACTORY = "FACTORY",
  HEMINGWAY = "HEMINGWAY",
  SCRAPER = "SCRAPER",
}

/**
 * The possible statuses a Listing can have. (Name is legacy)
 */
export enum PostStatusEnum {
  CONFIRMED = "CONFIRMED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  NEW = "NEW",
  REJECTED = "REJECTED",
}

/**
 * An enumeration.
 */
export enum PrivacyStatusEnum {
  ANONYMOUS = "ANONYMOUS",
  PUBLIC = "PUBLIC",
}

/**
 * The type of member a user is in a recruiting team.
 */
export enum RecruitingTeamMemberRoleEnum {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}

/**
 * 'choices' is an iterable containing (actual value, human-readable name) tuples.
 * Stripe only supports day, week, month, year. (Note lowercase).
 * Intervals can be added to do "every 2 months" or "every 5 years".
 */
export enum RecurringScheduleEnum {
  month = "month",
  quarter = "quarter",
  year = "year",
}

/**
 * The states a Search Agreement can be in.
 */
export enum SearchAgreementStatusEnum {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

/**
 * The different kind of sources possible.
 */
export enum SourceKindEnum {
  BEEHIIV = "BEEHIIV",
  CSV = "CSV",
  SLACK = "SLACK",
  SUBSTACK = "SUBSTACK",
}

/**
 * The different states that a source can be in.
 */
export enum SourceStatusEnum {
  ERROR = "ERROR",
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  READY = "READY",
}

/**
 * An enumeration.
 */
export enum SubscriptionApprovalStatusEnum {
  APPROVED = "APPROVED",
  NEW = "NEW",
  REJECTED = "REJECTED",
}

/**
 * The status of a Stripe Subscription.
 */
export enum SubscriptionStatusEnum {
  active = "active",
  canceled = "canceled",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  trialing = "trialing",
  unpaid = "unpaid",
}

/**
 * An enumeration.
 */
export enum WorkStatusEnum {
  CLOSED_TO_CONVERSATIONS = "CLOSED_TO_CONVERSATIONS",
  OPEN_TO_OFFERS = "OPEN_TO_OFFERS",
  READY_TO_INTERVIEW = "READY_TO_INTERVIEW",
  UNKNOWN = "UNKNOWN",
}

export interface BulkListingInputType {
  listingUuid?: string | null;
  applylistSlug: string;
  promptAnswer?: string | null;
  enabled: boolean;
}

/**
 * The set of filters to be applied to a list of groups.
 * *** Should match CollectiveFilterType. ***
 */
export interface CollectiveFilterInputType {
  industries?: string[] | null;
  jobTypeCategories?: string[] | null;
}

/**
 * A set of parameters describing an export.
 */
export interface ExportParametersInputType {
  filterBy?: string | null;
  includeFields?: string | null;
}

/**
 * The set of candidate filters related to locations.
 */
export interface LocationFilterInputType {
  officePreference: OfficePreferenceEnum;
  locations: LocationPreferenceInputType[];
}

export interface LocationInputType {
  geonameId: number;
  locationType: LocationTypeEnum;
}

export interface LocationPreferenceInputType {
  geonameId: number;
  locationType: LocationTypeEnum;
  matchInterests: boolean;
}

/**
 * A collection of attributes related to a profile.
 */
export interface ProfileDataInputType {
  firstName?: string | null;
  lastName?: string | null;
  currentCity?: number | null;
  linkedinUrl?: string | null;
}

/**
 * The set of filters to be applied to a search for candidates.
 */
export interface SearchCandidatesFilterInputType {
  dropStatus?: string[] | null;
  experienceLevels?: string[] | null;
  hasWorkAuthorizationUsa?: boolean | null;
  includeSkipped?: boolean | null;
  onlyLiked?: boolean | null;
  locationPreferences?: LocationFilterInputType | null;
  pallets?: string[] | null;
  roles?: string[] | null;
  workTypes?: string[] | null;
  keywords?: string[] | null;
}

/**
 * A set of parameters directly describing a search.
 */
export interface SearchParametersInputType {
  q?: string | null;
  queryBy?: string | null;
  filterBy?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
