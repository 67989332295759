import { useQuery } from "@apollo/client"
import {
    AsyncMultiSelect,
    AsyncMultiSelectProps,
    AsyncSingleSelect,
    AsyncSingleSelectProps,
} from "@pallet-hq/hegel"
import { CITIES_QUERY } from "./queries"
import { CitiesQuery } from "__generated__/CitiesQuery"
import { getSelectOptions2 } from "components/inputs/Select/utils"

type _CitySelectProps = AsyncSingleSelectProps | AsyncMultiSelectProps
export type CitySelectProps = Omit<_CitySelectProps, "loadOptions"> & {
    isMulti: boolean
}

const CitySelect = (props: CitySelectProps) => {
    const SelectComponent = props.isMulti ? AsyncMultiSelect : AsyncSingleSelect
    const { data, refetch } = useQuery<CitiesQuery>(CITIES_QUERY, {
        variables: { searchInput: "", first: 20 },
    })

    return (
        <SelectComponent
            {...props}
            defaultOptions={
                data?.cities
                    ? getSelectOptions2(
                          data?.cities.edges,
                          "geonameId",
                          "longLabel",
                          "shortLabel",
                          ["latitude", "longitude"]
                      )
                    : []
            }
            loadOptions={async (searchInput: string) => {
                let options = refetch({
                    searchInput: searchInput,
                    first: 20,
                }).then(({ data }) => {
                    if (!data || !data.cities || !searchInput) {
                        return []
                    } else {
                        return getSelectOptions2(
                            data.cities.edges,
                            "geonameId",
                            "longLabel",
                            "shortLabel",
                            ["latitude", "longitude"]
                        )
                    }
                })
                return options
            }}
            isSearchable
        />
    )
}

export default CitySelect
