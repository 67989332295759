import { gql, useQuery } from "@apollo/client"
import {
    BaseStyledProps,
    MultiSelect,
    MultiSelectProps,
    SingleSelect,
    SingleSelectProps,
} from "@pallet-hq/hegel"
import { JobTypesQuery } from "__generated__/JobTypesQuery"
import { getSelectOptions } from "./utils"

export const JOB_TYPE_QUERY = gql`
    query JobTypesQuery {
        jobTypes {
            id
            slug
            name
            experienceName
        }
    }
`

export type JobTypeSelectProps = {
    isMulti?: boolean
} & BaseStyledProps &
    Omit<SingleSelectProps | MultiSelectProps, "options">

const JobTypeSelect = ({
    placeholder = "Select",
    isMulti = false,
    ...props
}: JobTypeSelectProps) => {
    const SelectComponent = isMulti ? MultiSelect : SingleSelect
    const { data } = useQuery<JobTypesQuery>(JOB_TYPE_QUERY)

    return (
        <SelectComponent
            {...props}
            placeholder={placeholder}
            isSearchable
            options={getSelectOptions(
                data?.jobTypes,
                "slug",
                "name",
                undefined,
                undefined,
                ["experienceName"]
            )}
        />
    )
}

export default JobTypeSelect
